<template>
  <div class="second-list-wrapper">
    <div class="query">
      <div class="query-item" v-if="!isApartmentTab">
        <div class="item-label">类型</div>
        <div class="item-options">
          <div
            class="option"
            :class="{ isCheck: item.isCheck }"
            @click="handleItem(item, constant.rentList, 10, '出租类型：')"
            v-for="item in constant.rentList"
            :key="item.value"
          >
            {{ item.label }}
          </div>
        </div>
      </div>
      <div class="query-item">
        <div class="item-label">区域</div>
        <div class="item-options">
          <div
            class="option"
            :class="{ isCheck: item.isCheck }"
            @click="handleItem(item, constant.areaList, 1, '区域：')"
            v-for="item in constant.areaList"
            :key="item.value"
          >
            {{ item.label }}
          </div>
        </div>
      </div>
      <div class="query-item">
        <div class="item-label">租金</div>
        <div class="item-options">
          <div
            class="option"
            :class="{ isCheck: item.isCheck }"
            @click="handleItem(item, constant.priceList, 11, '租金：')"
            v-for="item in constant.priceList"
            :key="item.value"
          >
            {{ item.label }}
          </div>
        </div>
      </div>
      <div class="query-item" v-if="!isApartmentTab">
        <div class="item-label">面积</div>
        <div class="item-options">
          <div
            class="option"
            :class="{ isCheck: item.isCheck }"
            @click="handleItem(item, constant.squareList, 3, '面积：')"
            v-for="item in constant.squareList"
            :key="item.value"
          >
            {{ item.label }}
          </div>
        </div>
      </div>
      <div class="query-item" v-if="!isApartmentTab">
        <div class="item-label">房型</div>
        <div class="item-options">
          <div
            class="option"
            :class="{ isCheck: item.isCheck }"
            @click="handleItem(item, constant.layoutList, 4, '房型：')"
            v-for="item in constant.layoutList"
            :key="item.value"
          >
            {{ item.label }}
          </div>
        </div>
      </div>
      <div class="query-item" v-if="!isApartmentTab">
        <div class="item-label">来源</div>
        <div class="item-options">
          <div
            class="option"
            :class="{ isCheck: item.isCheck }"
            @click="handleItem(item, constant.sourceList, 9, '来源：')"
            v-for="item in constant.sourceList"
            :key="item.value"
          >
            {{ item.label }}
          </div>
        </div>
      </div>
      <div class="query-item" v-if="isApartmentTab">
        <div class="item-label">配套</div>
        <div class="item-options">
          <div
            class="option"
            :class="{ isCheck: item.isCheck }"
            @click="handleItem(item, constant.collocationList, 17, '配套：')"
            v-for="item in constant.collocationList"
            :key="item.value"
          >
            {{ item.label }}
          </div>
        </div>
      </div>

      <div class="query-item" :class="{ 'clear-after': !isCheck }">
        <div class="item-label" style="margin-bottom: 10px">更多</div>
        <div class="item-options">
          <template v-if="!isApartmentTab">
            <el-select
              v-model="form.decorate"
              placeholder="请选择装修类型"
              size="small"
              class="mr-40 width-190"
              @change="situationChange"
            >
              <el-option
                v-for="item in constant.situationList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <el-select
              v-model="form.payWay"
              placeholder="请选择支付类型"
              size="small"
              class="mr-40 width-190"
              @change="paywayChange"
            >
              <el-option
                v-for="item in constant.paywayList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <el-select
              v-model="form.floor"
              placeholder="请选择楼层"
              size="small"
              class="mr-40 width-190"
              @change="floorChange"
            >
              <el-option
                v-for="item in constant.floorList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <div>
              <el-button
                size="small"
                :icon="Search"
                @click="getMoreSearch"
                class="search-btn"
              >
                更多搜索
                <el-icon class="el-icon--right" size="12" v-if="!isSearch">
                  <ArrowDown />
                </el-icon>
                <el-icon class="el-icon--right" size="12" v-else>
                  <ArrowUp />
                </el-icon>
              </el-button>
            </div>
          </template>
          <template v-else>
            <el-input
              v-model="form.projectName"
              placeholder="请输入公寓名称"
              clearable
              size="small"
              @input="projectNameChange"
              @clear="projectNameClear"
              style="width: 29%"
            />
            <el-input
              v-model="form.itemName"
              placeholder="项目关键字"
              clearable
              size="small"
              @input="itemNameChange"
              @clear="itemNameClear"
              style="width: 29%; margin-left: 16px"
            />
          </template>
        </div>
      </div>
      <div
        class="query-item clear-after"
        v-if="isSearch && !isApartmentTab"
        style="border: solid 1px #dcdfe6; padding: 20px; margin-bottom: 10px"
      >
        <el-input
          v-model="form.houseVerifyCode"
          placeholder="请输入房源核验统一编码"
          clearable
          size="small"
          @input="codeChange"
          @clear="codeClear"
          style="width: 30%; margin-right: 10px"
        >
          <template #append>
            <el-icon size="14">
              <Search />
            </el-icon>
          </template>
        </el-input>
        <el-input
          v-model="form.projectName"
          placeholder="请输入小区关键字"
          clearable
          size="small"
          @input="projectNameChange"
          @clear="projectNameClear"
          style="width: 30%; margin-right: 10px"
        >
          <template #append>
            <el-icon size="14">
              <Search />
            </el-icon>
          </template>
        </el-input>

        <el-input
          v-model="form.companyName"
          placeholder="请输入企业名称"
          clearable
          size="small"
          @input="companyChange"
          @clear="companyClear"
          style="width: 30%"
        >
          <template #append>
            <el-icon size="14">
              <Search />
            </el-icon>
          </template>
        </el-input>
      </div>
      <div
        class="query-item clear-after"
        v-if="isCheck"
        :class="{ 'clear-after': isCheck }"
      >
        <div class="item-label">已选择</div>
        <div class="item-options">
          <el-tag
            v-for="tag in tags"
            :key="tag.name"
            closable
            :type="tag.type"
            class="mr-20"
            @close="closeTag(tag)"
          >
            {{ tag.name }}
          </el-tag>
        </div>
      </div>
    </div>
    <div class="list-wrapper">
      <div class="tabs-wrapper">
        <el-main
          style="padding: 0"
          v-loading="loadingShow"
          element-loading-background="rgba(0, 0, 0, 0.3)"
          element-loading-text="数据正在加载中..."
        >
          <el-tabs v-model="activeName" @tab-click="tableClick" type="card">
            <el-tab-pane label="企业房源" name="fourth">
              <template v-if="activeName === 'fourth'">
                <template v-if="originData.length">
                  <nesi-house-item
                    v-for="item in originData"
                    :key="item"
                    :item="item"
                    :minio="minio"
                    @click="toDetail(item)"
                  ></nesi-house-item>
                </template>
                <template v-else-if="loadingShow">
                  <div class="no-records"></div>
                </template>
                <template v-else>
                  <div class="no-records">~无交易记录~</div>
                </template>
              </template>
            </el-tab-pane>

            <el-tab-pane label="个人房源" name="second">
              <template v-if="activeName === 'second'">
                <template v-if="originData.length">
                  <nesi-house-item
                    v-for="item in originData"
                    :key="item"
                    :item="item"
                    :minio="minio"
                    @click="toDetail(item)"
                  ></nesi-house-item>
                </template>
                <template v-else-if="loadingShow">
                  <div class="no-records"></div>
                </template>
                <template v-else>
                  <div class="no-records">~无交易记录~</div>
                </template>
              </template>
            </el-tab-pane>
            <el-tab-pane label="居间代理" name="third">
              <template v-if="activeName === 'third'">
                <template v-if="originData.length">
                  <nesi-house-item
                    v-for="item in originData"
                    :key="item"
                    :item="item"
                    :minio="minio"
                    @click="toDetail(item)"
                  ></nesi-house-item>
                </template>
                <template v-else-if="loadingShow">
                  <div class="no-records"></div>
                </template>
                <template v-else>
                  <div class="no-records">~无交易记录~</div>
                </template>
              </template>
            </el-tab-pane>

            <el-tab-pane label="集中式公寓" name="fifth">
              <template v-if="activeName === 'fifth'">
                <template v-if="originApartmentData.length">
                  <nesi-apartment-item
                    v-for="item in originApartmentData"
                    :key="item"
                    :item="item"
                    :minio="minio"
                    types="2"
                    @click="toApartmentDetail(item, 5)"
                  ></nesi-apartment-item>
                </template>
                <template v-else-if="loadingShow">
                  <div class="no-records"></div>
                </template>
                <template v-else>
                  <div class="no-records">~无交易记录~</div>
                </template>
              </template>
            </el-tab-pane>
            <el-tab-pane label="预告房源" name="sixth">
              <template v-if="activeName === 'sixth'">
                <template v-if="originApartmentData.length">
                  <nesi-apartment-item
                    v-for="item in originApartmentData"
                    :key="item"
                    :item="item"
                    :minio="minio"
                    types="1"
                    @click="toApartmentDetail(item, 6)"
                  ></nesi-apartment-item>
                </template>
                <template v-else-if="loadingShow">
                  <div class="no-records"></div>
                </template>
                <template v-else>
                  <div class="no-records">~无交易记录~</div>
                </template>
              </template>
            </el-tab-pane>
            <!-- <el-tab-pane label="保租房" name="seven">
              <template v-if="activeName === 'seven'">
                <template v-if="originData.length">
                  <nesi-house-item
                    v-for="item in originData"
                    :key="item"
                    :item="item"
                    :minio="minio"
                    @click="toDetail(item)"
                  ></nesi-house-item>
                </template>
                <template v-else-if="loadingShow">
                  <div class="no-records"></div>
                </template>
                <template v-else>
                  <div class="no-records">~无交易记录~</div>
                </template>
              </template>
            </el-tab-pane> -->
            <el-tab-pane label="全部" name="first">
              <template v-if="activeName === 'first'">
                <template v-if="originData.length">
                  <nesi-house-item
                    v-for="item in originData"
                    :key="item"
                    :item="item"
                    :minio="minio"
                    @click="toDetail(item)"
                  ></nesi-house-item>
                </template>
                <template v-else-if="loadingShow">
                  <div class="no-records"></div>
                </template>
                <template v-else>
                  <div class="no-records">~无交易记录~</div>
                </template>
              </template>
            </el-tab-pane>
            <div
              class="pagination"
              v-if="originData.length || originApartmentData.length"
            >
              <el-pagination
                background
                layout="total, prev, pager, next"
                :current-page="pagation.pageNo"
                :page-size="pagation.pageSize"
                :total="pagation.total"
                @current-change="handleCurrentChange"
              ></el-pagination>
            </div>
          </el-tabs>
        </el-main>
      </div>
    </div>
  </div>
</template>
<script>
import {
  SQUARE_MAP,
  SALE_MAP,
  LAYOUT_MAP,
  SITUATIONS_MAP,
  TOWARD_MAP,
  USE_MAP,
  AREA_MAP,
  SOURCE_MAP,
  RENT_TYPE_MAP,
  PRICE_MAP,
  PAYWAY_MAP,
  FLOOR_MAP,
  SORT_TYPE_MAP,
  COLLOCATION_NAME
} from '@/constant'
import { deepCopy } from '@/utils/util'
import { defineComponent, onMounted, reactive, ref, computed } from 'vue'
import { newHouseList, searchItem } from '@/api/home'
import { useRouter, useRoute } from 'vue-router'
import NesiHouseItem from '@/components/NesiHouseItem/index.vue'
import NesiApartmentItem from '@/components/NesiApartmentItem'
import { useStore } from 'vuex'

export default defineComponent({
  components: { NesiApartmentItem, NesiHouseItem },
  name: 'list',
  setup() {
    const isCheck = ref(false)
    const isLoading = ref(true)
    const loadingShow = ref(true)
    const activeName = ref('fourth')
    const type = ref(0) // 房源类型
    const isSearch = ref(false)
    const originData = ref([])
    const originApartmentData = ref([])
    const minio = ref('')
    const router = useRouter()
    const route = useRoute()
    const form = reactive({
      houseType: 1, // 查询房源类型：1-企业房源，2-居间代理，3-个人房源，4-全部
      rentType: '', // 出租类型： 1-整租，2-分租， ''-不限
      companyName: '',
      districtId: '',
      houseVerifyCode: '',
      inFloorMax: '',
      inFloorMin: '',
      payWay: '',
      projectName: '',
      rentMaxPrice: '',
      rentMaxSpace: '',
      rentMinPrice: '',
      rentMinSpace: '',
      roomNum: '',
      situation: '',
      source: '',
      type: '2',
      apartmentSearchtype: '2', // 查询类型1预告类型，2集中式公寓类型
      collocation: '', // 公寓特色
      itemName: ''
    })
    const searchName = ref(route.query.name)
    if (searchName.value) {
      form.projectName = searchName.value
    }
    const store = useStore()
    const sourceList = computed(() => {
      return store.getters.sourceList
    })
    const getSourceList = () => {
      const tempList = { value: '0', label: '不限' }
      const defaultList = []
      defaultList.push(tempList)
      const list = sourceList.value.map((item) => {
        return {
          value: item.source,
          label: item.source
        }
      })
      return [...defaultList, ...list]
    }
    const constant = reactive({
      userList: deepCopy(USE_MAP),
      areaList: deepCopy(AREA_MAP),
      saleList: deepCopy(SALE_MAP),
      priceList: deepCopy(PRICE_MAP),
      squareList: deepCopy(SQUARE_MAP),
      layoutList: deepCopy(LAYOUT_MAP),
      towardList: deepCopy(TOWARD_MAP),
      situationList: deepCopy(SITUATIONS_MAP),
      // sourceList: deepCopy(SOURCE_MAP),
      sourceList: deepCopy(getSourceList()),
      rentList: deepCopy(RENT_TYPE_MAP),
      paywayList: deepCopy(PAYWAY_MAP),
      floorList: deepCopy(FLOOR_MAP),
      sortList: deepCopy(SORT_TYPE_MAP),
      collocationList: deepCopy(COLLOCATION_NAME)
    })

    const tags = ref([])

    const duplicate = (type) => {
      const index = tags.value.findIndex((item) => {
        return item.type === type
      })
      if (index !== -1) tags.value.splice(index, 1)
    }

    const pagation = reactive({
      total: 0,
      pageNo: 1,
      pageSize: 5
    })

    const getMoreSearch = () => {
      isSearch.value = !isSearch.value
    }

    const getParams = () => {
      const params = {}
      tags.value.map((item) => {
        switch (item.type) {
          case 1:
            if (item.value !== '0') {
              params.districtId = item.value
            }
            break
          case 2:
            if (item.value.split('_').length === 2) {
              params.lowPrice = item.value.split('_')[0]
              params.heightPrice = item.value.split('_')[1]
              if (params.lowPrice === '250') {
                delete params.heightPrice
              }
            }
            break
          case 3:
            if (item.value.split('_').length === 2) {
              params.rentMinSpace = item.value.split('_')[0]
              params.rentMaxSpace = item.value.split('_')[1]
              if (params.rentMinSpace === '300') {
                delete params.rentMaxSpace
              }
            }
            break
          case 4:
            if (item.value !== '0') {
              params.roomNum = item.value
            }
            break
          case 5:
            if (item.value !== '0') {
              params.toward = item.value
            }
            break
          case 6:
            if (item.value !== '0') {
              params.situation = item.value
            }
            break
          case 8:
            if (form.projectName) {
              params.projectName = form.projectName
            }
            break
          case 9:
            if (item.value !== '0') {
              params.source = item.value
            }
            break
          case 10:
            if (item.value !== '0') {
              params.rentType = item.value
            }
            break
          case 11:
            if (item.value.split('_').length === 2) {
              params.rentMinPrice = item.value.split('_')[0]
              params.rentMaxPrice = item.value.split('_')[1]
              if (params.rentMinPrice === '5000') {
                delete params.rentMaxPrice
              }
            }
            break
          case 12:
            params.payWay = item.value
            break
          case 14:
            if (item.value.split('_').length === 2) {
              params.inFloorMin = item.value.split('_')[0]
              params.inFloorMax = item.value.split('_')[1]
            } else {
              if (item.value === '6') {
                params.inFloorMax = 6
              } else if (item.value === '10') {
                params.inFloorMin = 10
              }
            }
            break
          case 15:
            if (form.houseVerifyCode) {
              params.houseVerifyCode = form.houseVerifyCode
            }
            break
          case 16:
            if (form.companyName) {
              params.companyName = form.companyName
            }
            break
          case 17:
            if (form.collocation) {
              params.collocation = form.collocation
            }
            break
          case 18:
            if (form.itemName) {
              params.itemName = form.itemName
            }
            break
          default:
            console.log('default')
        }
      })
      if (type.value && type.value !== 0) params.type = type.value
      return params
    }
    const queryListByType = (reload = false) => {
      if (isApartmentTab.value) {
        queryApartmentList(reload)
      } else {
        queryList(reload)
      }
    }
    const queryList = async (isPageReset) => {
      // 点击条件时候pageNo设置为 1，点击分页的时候pageNo + 1， 参数不能变
      if (isPageReset) {
        pagation.pageNo = 1
      }
      const params = getParams()
      const payload = {
        ...{
          pageNo: pagation.pageNo,
          pageSize: pagation.pageSize,
          type: form.type,
          houseType: form.houseType
        },
        ...params
      }

      loadingShow.value = true
      // console.log('执行')
      // return
      const res = await newHouseList(payload)
      pagation.total = Number(res.records.total)
      originData.value = res.records.records
      minio.value = res.minioUrl
      loadingShow.value = false
    }

    const handleCurrentChange = (val) => {
      pagation.pageNo = val
      queryListByType(false)
    }

    const handleTag = (item, type, desc) => {
      const option = { name: desc + item.label, type, value: item.value }
      duplicate(type)
      tags.value.push(option)
      isCheck.value = true
      pagation.pageNo = 1
      queryListByType(true)
    }

    const projectNameChange = (value) => {
      if (value) {
        form.projectName = value
        handleTag(
          { label: value, value: '', isCheck: true },
          8,
          '小区名称关键字：'
        )
      } else {
        tags.value = tags.value.filter((item) => item.type !== 8)
        isCheck.value = false
        form.projectName = ''
        queryListByType(true)
      }
    }

    const codeChange = (value) => {
      if (value) {
        form.houseVerifyCode = value
        handleTag(
          { label: value, value: '', isCheck: true },
          15,
          '房源核验码：'
        )
      } else {
        tags.value = tags.value.filter((item) => item.type !== 15)
        isCheck.value = false
        form.houseVerifyCode = ''
        queryList(true)
      }
    }

    const codeClear = () => {
      tags.value = tags.value.filter((item) => item.type !== 15)
      isCheck.value = false
      form.houseVerifyCode = ''
      queryList(true)
    }

    const companyChange = (value) => {
      if (value) {
        form.companyName = value
        handleTag({ label: value, value: '', isCheck: true }, 16, '企业名称：')
      } else {
        tags.value = tags.value.filter((item) => item.type !== 16)
        isCheck.value = false
        form.companyName = ''
        queryList(true)
      }
    }

    const companyClear = () => {
      tags.value = tags.value.filter((item) => item.type !== 16)
      isCheck.value = false
      form.companyName = ''
      queryList(true)
    }

    const projectNameClear = () => {
      tags.value = tags.value.filter((item) => item.type !== 8)
      isCheck.value = false
      form.projectName = ''
      queryListByType(true)
    }

    const itemNameChange = (value) => {
      if (value) {
        form.itemName = value
        console.log(value)
        handleTag({ label: value, value: '', isCheck: true }, 18, '项目：')
      } else {
        tags.value = tags.value.filter((item) => item.type !== 18)
        isCheck.value = false
        form.itemName = ''
        queryListByType(true)
      }
    }

    const itemNameClear = () => {
      tags.value = tags.value.filter((item) => item.type !== 18)
      isCheck.value = false
      form.itemName = ''
      queryListByType(true)
    }

    const toDetail = (item) => {
      if (item.upStatus === 12 || item.upStatus === 14) {
        return
      }
      if (item.source) {
        window.open(item.linkAddress, '_blank')
      } else {
        const routeUrl = router.resolve({
          name: 'houseDetail',
          query: { id: item.houseId }
        })
        window.open(routeUrl.href, '_blank')
      }
    }
    const toApartmentDetail = (item, type) => {
      const routeUrl = router.resolve({
        name: 'apartmentDetail',
        query: { id: item.id, type }
      })
      window.open(routeUrl.href, '_blank')
    }

    const handleItem = (item, list, type, desc) => {
      list = list.map((area) => {
        if (item.value === area.value) {
          area.isCheck = true
        } else {
          area.isCheck = false
        }
        return area
      })
      handleTag(item, type, desc)
    }

    // const sourceChange = (value: string) => {
    //   const source = constant.sourceList.find((item: Item) => item.value === value)
    //   if (source) handleTag(source, 8, '来源：')
    // }

    const useChange = (value) => {
      const use = constant.userList.find((item) => item.value === value)
      if (use) handleTag(use, 7, '产权：')
    }

    const situationChange = (value) => {
      const situation = constant.situationList.find(
        (item) => item.value === value
      )
      if (situation) handleTag(situation, 6, '装修：')
    }

    const towardChange = (value) => {
      const toward = constant.towardList.find((item) => item.value === value)
      if (toward) handleTag(toward, 5, '朝向：')
    }

    const paywayChange = (value) => {
      const payway = constant.paywayList.find((item) => item.value === value)
      if (payway) handleTag(payway, 12, '支付方式：')
    }

    const floorChange = (value) => {
      const floor = constant.floorList.find((item) => item.value === value)
      if (floor) handleTag(floor, 14, '楼层：')
    }

    const closeTag = (tag) => {
      switch (tag.type) {
        case 1:
          constant.areaList = deepCopy(AREA_MAP)
          break
        case 2:
          constant.saleList = deepCopy(SALE_MAP)
          break
        case 3:
          constant.squareList = deepCopy(SQUARE_MAP)
          break
        case 4:
          constant.layoutList = deepCopy(LAYOUT_MAP)
          break
        case 5:
          form.toward = ''
          break
        case 6:
          form.decorate = ''
          break
        case 7:
          form.use = ''
          break
        case 8:
          form.projectName = ''
          break
        case 9:
          constant.sourceList = deepCopy(SOURCE_MAP)
          break
        case 10:
          constant.rentList = deepCopy(RENT_TYPE_MAP)
          break
        case 11:
          constant.priceList = deepCopy(PRICE_MAP)
          break
        case 12:
          form.payWay = ''
          break
        case 13:
          constant.sortList = deepCopy(SORT_TYPE_MAP)
          break
        case 14: // 楼层
          form.inFloorMax = ''
          form.inFloorMin = ''
          break
        case 15:
          form.houseVerifyCode = ''
          break
        case 16:
          form.companyName = ''
          break
        case 17:
          form.collocation = ''
          constant.collocationList = deepCopy(COLLOCATION_NAME)
          break
        case 18:
          form.itemName = ''
          break
        default:
      }
      duplicate(tag.type)
      if (!tags.value.length) isCheck.value = false
      if (isApartmentTab.value) {
        queryApartmentList(true)
      } else {
        queryList(true)
      }
    }

    const custHouseList = async () => {
      if (form.projectName) {
        handleTag(
          { label: form.projectName, value: form.projectName, isCheck: true },
          8,
          '小区名称关键字：'
        )
      } else {
        const payload = { pageNo: pagation.pageNo, pageSize: pagation.pageSize }
        loadingShow.value = true
        console.log(payload)
        // const res = await axios.post(URL.houseApi.houseList, payload)
        // if (res.code === 200) {
        //   pagation.total = res.result.records.total
        //   minio.value = res.result.minio
        //   originData.value = res.result.records.records
        // }
        // loadingShow.value = false
      }
    }

    const tableClick = (val) => {
      // 查询房源类型：1-企业房源，2-居间代理，3-个人房源，4-全部,5集中式公寓，6预告房源
      if (val.props.name === 'first') {
        form.houseType = 4
      } else if (val.props.name === 'second') {
        form.houseType = 3
      } else if (val.props.name === 'third') {
        form.houseType = 2
      } else if (val.props.name === 'fourth') {
        form.houseType = 1
      } else if (val.props.name === 'fifth') {
        form.apartmentSearchtype = 2
        queryApartmentList(true)
        return
      } else if (val.props.name === 'sixth') {
        form.apartmentSearchtype = 1
        queryApartmentList(true)
        return
      } else if (val.props.name === 'seven') {
        form.houseType = 5
      }

      queryList(true)
    }

    /**
     *
     * @param isPageReset 是否刷新页面
     */
    const queryApartmentList = async (isPageReset = true) => {
      if (isPageReset) {
        pagation.pageNo = 1
      }
      const params = getParams()
      const payload = {
        ...params,
        pageNo: pagation.pageNo,
        pageSize: pagation.pageSize,
        type: form.apartmentSearchtype
      }

      loadingShow.value = true
      const res = await searchItem(payload)
      console.log(res)
      pagation.total = Number(res.records.total)
      originApartmentData.value = res.records.records
      minio.value = res.minioUrl
      loadingShow.value = false
    }

    // const bzfData = ref([])

    onMounted(async () => {
      if (route.params.projectName) {
        activeName.value = 'first'
        form.projectName = route.params.projectName
        const params = { props: { name: 'first' } }
        await custHouseList()
        tableClick(params)
        console.log('++++++')
        return
      }
      if (route.query.tab) {
        // 企业页面跳转查询，模拟点击请求
        activeName.value = route.query.tab
        const params = { props: { name: route.query.tab } }
        tableClick(params)
      } else {
        queryList(true)
      }
      // console.log(route)
      custHouseList()
    })
    const isApartmentTab = computed(() => {
      return ['fifth', 'sixth'].indexOf(activeName.value) != -1
    })

    return {
      loadingShow,
      handleCurrentChange,
      isLoading,
      tableClick,
      projectNameClear,
      projectNameChange,
      codeClear,
      codeChange,
      toDetail,
      minio,
      originData,
      pagation,
      isCheck,
      form,
      constant,
      activeName,
      handleItem,
      tags,
      useChange,
      situationChange,
      towardChange,
      paywayChange,
      floorChange,
      closeTag,
      companyClear,
      companyChange,
      originApartmentData,
      toApartmentDetail,
      isApartmentTab,
      itemNameChange,
      itemNameClear,
      getMoreSearch,
      isSearch
      // bzfData
    }
  }
})
</script>

<style scoped lang="scss">
.second-list-wrapper {
  background: #f8f8f8;
  .query {
    width: 100%;
    margin: 0 0 20px;
    // border: solid 1px #e4e7ed;
    // box-shadow: 0 0 5px 5px #e4e7ed;
    font-size: 13px;
    background-color: #ffffff;
    padding-bottom: 10px;

    .query-item {
      width: 96%;
      display: flex;
      align-items: center;
      padding: 15px 0;
      margin: 0 20px;
      position: relative;
      // background-color: #ffffff;

      &::after {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        display: inline-block;
        content: '';
        height: 1px;
        background-size: 5px 1px;
        background-image: linear-gradient(90deg, #e7ebee 60%, #fff 0);
        background-repeat: repeat-x;
      }

      &.clear-after::after {
        content: none;
      }

      .item-label {
        width: 60px;
        color: #909399;
      }

      .item-options {
        display: flex;
        flex-wrap: wrap;
        flex: 1;

        .option {
          margin-right: 20px;

          &:hover {
            color: #2979ff;
            cursor: pointer;
            text-decoration: underline;
          }
        }
      }
    }
  }

  .list-wrapper {
    // height: 400px;

    .tabs-wrapper {
      width: 100%;
      background: #ffffff;
    }
  }
}

.mr-40 {
  margin-right: 40px;
}

.mr-20 {
  margin-right: 20px;
}

.width-190 {
  width: 190px;
  margin-bottom: 10px;
}

.isCheck {
  color: #2979ff;
}

::v-deep .el-tab-pane .wrapper:first-child {
  margin-top: 5px;
}

.pagination {
  padding: 10px 0;
  display: flex;
  justify-content: flex-end;

  .el-pagination {
    display: flex;
    align-items: center;
  }
}

::v-deep .el-tabs--border-card > .el-tabs__content {
  padding: 0;
}

.no-records {
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: center;
  justify-items: center;
  line-height: 300px;
}

::v-deep .el-tabs__item {
  height: 50px;
  line-height: 50px;
}

::v-deep .el-tabs--card > .el-tabs__header .el-tabs__nav {
  border-left: 1px solid #e5e5e5;
  border-right: 1px solid #e5e5e5;
  border-radius: none;
}
</style>

<template>
  <div class="house-item">
    <el-image class="image" :src="imgUrl" fit="cover">
      <template #error>
        <div
          style="
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            opacity: 0.8;
            background: #ecf5ff;
            flex-direction: column;
            color: #5298f5;
            font-size: 14px;
          "
        >
          <el-image
            style="width: 120px"
            fit="cover"
            src="https://pc.nnfwzl.com/rent-house/rent-platform-web/default.png"
          ></el-image>
          图片正在拍摄中
        </div>
      </template>
    </el-image>
    <div class="validate" v-if="types === '1'">预告房源</div>
    <div class="validate" v-else>产权认证</div>
    <div class="house-item-field">
      <div class="title">
        {{ item.apartmentName }}
      </div>
      <div class="field">项目位置：{{ item.apartmentLocation }}</div>
      <div class="field">所在城区：{{ item.locationName }}</div>
      <div class="field">主力房源：{{ item.mainRoomType }}</div>
      <div class="field field-auto">项目简介：{{ item.itemBrief }}</div>
      <div class="price">
        {{ item.priceBottom }}~ {{ item.priceTop }}
        <div class="unit">元/月</div>
      </div>

      <div
        class="tags"
        v-if="item.collocationName && item.collocationName.length"
      >
        <el-tag
          size="small"
          v-for="tag in item.collocationName.split(',')"
          :key="tag"
          >{{ tag }}
        </el-tag>
      </div>
    </div>
  </div>
</template>

<script>
// 集中式公寓Item
export default {
  name: 'NesiApartmentItem',
  props: {
    item: { type: Object, required: true },
    types: { type: [String, Number], required: true }, // 1预告类型，2集中式公寓类型
    minio: { type: String }
  },
  computed: {
    imgUrl() {
      const item = this.item
      if (
        !item.houseInfo ||
        item.houseInfo === '[]' ||
        !item.houseInfo.startsWith('[')
      ) {
        return ''
      }
      const imgJson = JSON.parse(item.houseInfo)
      const url = imgJson[0].src
      return url.indexOf('http') === -1 ? this.minio + url : url
    }
  }
}
</script>

<style lang="scss" scoped>
.tags {
  padding: 16px 0;

  .el-tag {
    margin-right: 20px;
  }
}

.house-item {
  position: relative;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  border: solid 1px #eee;
  padding: 0 10px;
  box-sizing: border-box;
  background: #fff;
  border-radius: 5px;
  cursor: pointer;

  .image {
    width: 300px;
    height: 200px;
  }

  .validate {
    position: absolute;
    left: 10px;
    top: 10px;
    color: #fff;
    font-size: 14px;
    background: #71d5a1;
    padding: 5px 8px;
    font-size: 13px;
    border: 1px solid #71d5a1;
  }

  .house-item-field {
    position: relative;
    margin-left: 10px;
    flex: 1;

    .field {
      height: 30px;
      line-height: 30px;
      font-size: 14px;
      color: #666;

      &.field-auto {
        height: auto;
        line-height: auto;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden; //溢出内容隐藏
        text-overflow: ellipsis; //文本溢出部分用省略号表示
        display: -webkit-box; //特别显示模式
        -webkit-line-clamp: 3; //行数
        line-clamp: 3;
        -webkit-box-orient: vertical; //盒子中内容竖直排列
      }
    }

    .title {
      padding-top: 5px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      height: 40px;
      line-height: 40px;
      color: #000;
      font-weight: 600;
      font-size: 18px;
    }

    .price {
      position: absolute;
      height: 51px;
      line-height: 51px;
      right: 41px;
      top: 30%;
      color: #db4c3f;
      font-size: 24px;
      font-weight: 600;
      display: flex;

      .unit {
        margin-left: 5px;
        font-size: 14px;
      }
    }
  }
}
</style>

<template>
  <div class="wrapper">
    <div class="item">
      <div class="left">
        <el-image
          class="image"
          :src="imgageSrc"
          referrer="no-referrer|origin|unsafe-url"
        >
          <template #error>
            <div class="image-slot" style="height: 100%">
              <div
                style="
                  width: 100%;
                  height: 100%;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  opacity: 0.8;
                  background: #ecf5ff;
                  flex-direction: column;
                  color: #5298f5;
                  font-size: 14px;
                "
              >
                <el-image
                  class="image"
                  style="width: 120px; height: 80px"
                  src="https://pc.nnfwzl.com/rent-house/rent-platform-web/default.png"
                />
                图片正在拍摄中
              </div>
            </div>
          </template>
        </el-image>
        <el-image
          v-if="thirdSource"
          style="position: absolute; right: 0; top: 0"
          :src="thirdSource"
          referrer="no-referrer|origin|unsafe-url"
        >
        </el-image>
      </div>
      <div class="center">
        <div class="title">
          {{ house.rentType === 2 ? '分租' : '整租' }} ·
          {{ house.projectName }} ·
          {{ house.rentType === 2 ? house.roomSpace : house.buildingArea }}㎡ ·
          {{ house.roomNum }}室{{ house.hallNum }}厅{{ house.toiletNum }}卫
        </div>
        <div class="tips-wrapper">
          <div class="tips">
            {{ house.towardName }} |
            {{ house.rentType === 2 ? house.roomSpace : house.buildingArea }}㎡
            |
            {{
              constant.situationList.find(
                (item) => item.value === house.situation
              )
                ? constant.situationList.find(
                    (item) => item.value === house.situation
                  ).label
                : '--'
            }}
            | {{ house.upAt.split(' ')[0] }}
          </div>
          <div class="tips">
            {{
              constant.areaList.find(
                (item) => item.value === house.areaId.toString()
              )
                ? constant.areaList.find(
                    (item) => item.value === house.areaId.toString()
                  ).label
                : '--'
            }}
            | {{ house.street }} |
            {{ house.projectName }}
          </div>
          <div class="tips">
            统一编码：{{ house.areaId + house.houseNumber }}
          </div>
          <div class="tags" v-if="tags.length">
            <el-tag :type="item.type" v-for="item in tags" :key="item"
              >{{ item.text }}
            </el-tag>
          </div>
          <div
            class="validate"
            :class="{
              'org-background':
                !house.govVerification ||
                (house.govVerification && house.govVerification === '0')
            }"
          >
            {{
              house.govVerification && house.govVerification !== '0'
                ? '产权核验'
                : '未核验'
            }}
          </div>
        </div>
      </div>
      <div class="right">
        <div class="total">
          {{ numberFormat(item.rentPrice, 2, '.', ',') }}元/月
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, reactive, computed } from 'vue'
import { AREA_MAP, SITUATIONS_MAP } from '@/constant'
import { deepCopy, numberFormat, getSource } from '@/utils/util'
import { useStore } from 'vuex'

export default defineComponent({
  name: 'nesi-house-item',
  props: {
    item: {
      type: Object
    },
    minio: {
      type: String
    }
  },
  setup(props) {
    const store = useStore()
    const constant = reactive({
      areaList: deepCopy(AREA_MAP),
      situationList: deepCopy(SITUATIONS_MAP)
    })
    const house = ref(props.item)
    const tags = ref([])
    const minio = ref(props.minio)
    const types = ['', 'success', 'info', 'warning', 'danger']
    if (house.value.houseTags && house.value.houseTags !== '[]') {
      tags.value = house.value.houseTags.split(',').map((item, index) => {
        return {
          text: item,
          type: types[index % 5]
        }
      })
    }

    let imgages = []
    let imgageSrc = ''
    console.log(house.value)
    if (house.value.source) {
      imgageSrc = house.value.imageUrl
    } else {
      if (house.value.upStatus === 12 || house.value.upStatus === 14) {
        imgageSrc =
          'https://pc.nnfwzl.com/rent-house/rent-platform-web/house-rented.jpg'
      } else if (house.value.houseOther) {
        imgages = JSON.parse(house.value.houseOther)
        imgageSrc = ref(minio.value + (imgages[0].src || imgages[0].smjsj))
      }
    }

    const sourceList = computed(() => {
      return store.getters.sourceList
    })
    let thirdSource = ''
    if (house.value.source) {
      const source = sourceList.value.find(
        (item) => item.source === house.value.source
      )
      thirdSource = source && source.src
      // thirdSource = getSource(house.value.source)
      // imgageSrc = house.value.imageUrl
    }
    return {
      house,
      tags,
      imgageSrc,
      thirdSource,
      constant,
      numberFormat
    }
  }
})
</script>

<style scoped lang="scss">
.wrapper {
  height: 200px;
  width: 100%;
  padding: 20px 15px;
  border-bottom: solid #e4e7ed 1px;
  margin-bottom: 10px;
  position: relative;

  &:hover {
    // background: #f5f7fa;
    cursor: pointer;
  }

  .item {
    display: flex;
    width: 100%;
    height: 100%;

    .left {
      position: relative;
      width: 250px;
      height: 158px;

      .third-image {
        width: 80px;
        height: 80px;
        position: absolute;
        right: -5px;
        top: -5px;
      }

      .image {
        width: 100%;
        height: 100%;
      }
    }

    .center {
      flex: 1;
      padding: 0 20px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .title {
        font-size: 20px;
        font-weight: 800;
        height: 40px;
        line-height: 40px;
      }

      .tips-wrapper {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .tips {
          color: #909399;
          height: 30px;
          line-height: 30px;
          font-size: 14px;
        }

        .validate {
          position: absolute;
          left: 15px;
          top: 20px;
          color: white;
          font-size: 14px;
          background: #54d286;
          padding: 5px 8px;
          font-size: 13px;
          border: solid 1px #54d286;
        }

        .org-background {
          background: #ff9900;
          border: solid 1px #ff9900;
        }

        .tags {
          .el-tag {
            margin-right: 20px;
          }
        }
      }
    }

    .right {
      width: 18%;
      padding: 0 20px;
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: center;

      .total {
        color: #fa3534;
        font-size: 24px;
        font-weight: 800;
      }

      .price {
        margin-top: 10px;
        color: #909399;
      }
    }
  }
}
</style>

import CryptoJS from 'crypto-js'

// import cryptoJs from 'crypto-js'
// 计算属性
const handleFields = (origin, fields) => {
  return fields.map((field) => {
    const fieldList = field.name.split('|')
    const fixList = field.fix.split('|')
    const value = fieldList.reduce((pre, item, index) => {
      if (field.translate && field.translate.length) {
        const option = field.translate.find(
          (tranItem) => tranItem.value === origin[item]
        )
        if (option) pre += option.label
        else pre += '--'
      } else pre += (origin[item] || '-') + fixList[index]
      return pre.split('、')[0]
    }, '')
    field.value = value
    return field
  })
}

// 简单深度拷贝
const deepCopy = (data) => {
  return JSON.parse(JSON.stringify(data))
}

const blobToBase64 = (blob) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader()
    fileReader.onload = (e) => {
      resolve(e.target.result)
    }
    // readAsDataURL
    fileReader.readAsDataURL(blob)
    fileReader.onerror = () => {
      reject(new Error('blobToBase64 error'))
    }
  })
}

// 加密
const encryptByDES = (message, key, iv) => {
  // const keyHex = cryptoJs.enc.Utf8.parse(key)
  // const keyIv = cryptoJs.enc.Utf8.parse(iv)
  // const srcs = cryptoJs.enc.Utf8.parse(message)
  // const encrypted = cryptoJs.AES.encrypt(srcs, keyHex, { iv: keyIv, mode: cryptoJs.mode.CBC })
  // return encrypted.toString()
}

/*
 * 参数说明：
 * number：要格式化的数字
 * decimals：保留几位小数
 * decPoint：小数点符号
 * thousandsSep：千分位符号
 * */
const numberFormat = (number, decimals, decPoint, thousandsSep) => {
  number = (number + '').replace(/[^0-9+-Ee.]/g, '')
  const n = !isFinite(+number) ? 0 : +number
  const prec = !isFinite(+decimals) ? 0 : Math.abs(decimals)
  const sep = typeof thousandsSep === 'undefined' ? ',' : thousandsSep
  const dec = typeof decPoint === 'undefined' ? '.' : decPoint
  let s = ''
  const toFixedFix = function (n, prec) {
    var k = Math.pow(10, prec)
    return '' + Math.floor(n * k) / k
  }
  s = (prec ? toFixedFix(n, prec) : '' + Math.floor(n)).split('.')
  var re = /(-?\d+)(\d{3})/
  while (re.test(s[0])) {
    s[0] = s[0].replace(re, '$1' + sep + '$2')
  }

  if ((s[1] || '').length < prec) {
    s[1] = s[1] || ''
    s[1] += new Array(prec - s[1].length + 1).join('0')
  }
  return s.join(dec)
}

// 根据身份证获取年龄
const getAgeById = (identityCard) => {
  var len = (identityCard + '').length
  if (len === 0) {
    return 0
  } else {
    if (len !== 15 && len !== 18) {
      // 身份证号码只能为15位或18位其它不合法
      return 0
    }
  }
  var strBirthday = ''
  if (len === 18) {
    // 处理18位的身份证号码从号码中得到生日和性别代码
    strBirthday =
      identityCard.substr(6, 4) +
      '/' +
      identityCard.substr(10, 2) +
      '/' +
      identityCard.substr(12, 2)
  }
  if (len === 15) {
    strBirthday =
      '19' +
      identityCard.substr(6, 2) +
      '/' +
      identityCard.substr(8, 2) +
      '/' +
      identityCard.substr(10, 2)
  }
  // 时间字符串里，必须是“/”
  var birthDate = new Date(strBirthday)
  var nowDateTime = new Date()
  var age = nowDateTime.getFullYear() - birthDate.getFullYear()
  // 再考虑月、天的因素;.getMonth()获取的是从0开始的，这里进行比较，不需要加1
  if (
    nowDateTime.getMonth() < birthDate.getMonth() ||
    (nowDateTime.getMonth() === birthDate.getMonth() &&
      nowDateTime.getDate() < birthDate.getDate())
  ) {
    age--
  }
  return age
}

// 金额数字转大写
const digitUppercase = (price) => {
  const fraction = ['角', '分']
  const digit = ['零', '壹', '贰', '叁', '肆', '伍', '陆', '柒', '捌', '玖']
  const unit = [
    ['元', '万', '亿'],
    ['', '拾', '佰', '仟']
  ]
  let num = Math.abs(price)
  let s = ''
  fraction.forEach((item, index) => {
    s += (digit[Math.floor(num * 10 * 10 ** index) % 10] + item).replace(
      /零./,
      ''
    )
  })
  s = s || '整'
  num = Math.floor(num)
  for (let i = 0; i < unit[0].length && num > 0; i += 1) {
    let p = ''
    for (let j = 0; j < unit[1].length && num > 0; j += 1) {
      p = digit[num % 10] + unit[1][j] + p
      num = Math.floor(num / 10)
    }
    s = p.replace(/(零.)*零$/, '').replace(/^$/, '零') + unit[0][i] + s
  }

  return s
    .replace(/(零.)*零元/, '元')
    .replace(/(零.)+/g, '零')
    .replace(/^整$/, '零元整')
}
/**
 * 获取加密后的密码信息
 * @param account
 * @param password
 * @returns {string}
 */
const encryptByPassword = (account, password) => {
  const key = account.substring(0, 8) + 'Easyma3-'
  const iv = key
  const keyHex = CryptoJS.enc.Utf8.parse(key)
  const keyIv = CryptoJS.enc.Utf8.parse(iv)
  const srcs = CryptoJS.enc.Utf8.parse(password)
  const encrypted = CryptoJS.AES.encrypt(srcs, keyHex, {
    iv: keyIv,
    mode: CryptoJS.mode.CBC
  })
  return encrypted.toString()
}

const getSource = (key) => {
  switch (key) {
    case '住朋网':
      return 'https://pc.nnfwzl.com/rent-house/rent-platform-web/zhupengwang.png'
    case '贝壳网':
      return 'https://pc.nnfwzl.com/rent-house/rent-platform-web/shell.png'
    case '58同城':
      return 'https://pc.nnfwzl.com/rent-house/rent-platform-web/58.png'
    case '房天下':
      return 'https://pc.nnfwzl.com/rent-house/rent-platform-web/fangtianxia.png'
    case '房无忧':
      return 'https://pc.nnfwzl.com/rent-house/rent-platform-web/fangwuyou.png'
    case '恒创爱家':
      return 'https://pc.nnfwzl.com/rent-house/rent-platform-web/hengchuang.png'
    case '兰庭':
      return 'https://pc.nnfwzl.com/rent-house/rent-platform-web/lanting.png'
    case '优居':
      return 'https://pc.nnfwzl.com/rent-house/rent-platform-web/yongju.png'
    case '正和兴':
      return 'https://pc.nnfwzl.com/rent-house/rent-platform-web/zhenghexin.png'
    case 'CCB建融家园':
      return 'https://pc.nnfwzl.com/rent-house/rent-platform-web/jianrong.png'
    case '邕有家':
      return 'https://pc.nnfwzl.com/rent-house/rent-platform-web/yyjia.png'
    case '安居客':
      return 'https://pc.nnfwzl.com/rent-house/rent-platform-web/anjuke.png'
    case '富航':
      return 'https://pc.nnfwzl.com/rent-house/rent-platform-web/fuhang.png'
    case '江山':
      return 'https://pc.nnfwzl.com/rent-house/rent-platform-web/jiangshan.png'
    case '全房通':
      return 'https://pc.nnfwzl.com/rent-house/rent-platform-web/quanfangtong.png'
    default:
      return ''
  }
}

export {
  handleFields,
  deepCopy,
  encryptByDES,
  blobToBase64,
  numberFormat,
  getAgeById,
  digitUppercase,
  encryptByPassword,
  getSource
}
